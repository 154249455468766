import "./src/assets/style/layout.css"
import "./src/fonts/fonts.css"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import ReCaptchaTest from "./src/utils/rechaptaTest"

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6Lf1qEEqAAAAAHAUqH5cp3IS2sIXTEZUL1QGuuG1">
    {element}
    {/*     <ReCaptchaTest /> */}
  </GoogleReCaptchaProvider>
)
