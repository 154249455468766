const theme = {
  colors: {
    text: "#131E26",
    background: "#f5f5f5",
    lightBackground: "#F5F5F5",
    primary: "#F93822",
    secondary: "#264A64",
    light: "#F5F5F5",
    dark: "#1B1B1B",
    lightGrey: "#999999",
  },
  fonts: {
    body: 'Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  breakpoints: ["48em", "64em", "90em"], // metti nel boilerplate

  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 300,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1640,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    kicker: {
      color: "primary",
      maxWidth: "fit-content",
      width: "fit-content",
      letterSpacing: "3px",
      fontSize: "14px",
      minWidth: "fit-content",
      textTransform: "uppercase",
      fontWeight: 400,
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      fontWeight: 400,
      mb: 3,
    },
    gradient: {
      my: 0,
      fontWeight: 400,
      fontFamily: "heading",
      lineHeight: 1.2,
      color: "transparent",
      background: "radial-gradient(circle, #FF4228 0%, #000000 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      fontSize: ["32px", "32px", "64px"],
    },
    light: {
      my: 0,
      fontWeight: 400,
      fontFamily: "heading",
      lineHeight: 1.2,
      color: "light",
      fontSize: ["32px", "32px", "64px"],
    },
    gradientLight: {
      my: 0,
      fontWeight: 300,
      fontFamily: "heading",
      lineHeight: 1.2,
      zIndex: 99,

      color: "transparent",
      background: "radial-gradient(circle, #FF4228 0%, #FFFFFF 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      fontSize: ["32px", "32px", "64px"],
    },
    h2: {
      my: 0,
      fontWeight: 300,
      fontFamily: "heading",
      lineHeight: 1.14,
      color: "dark",
      fontSize: ["44px", "44px", "96px"],
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1200px",
      padding: [3, 4],
    },
    normal: {
      maxWidth: "1400px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
  },
  buttons: {
    none: {
      bg: "transparent",
      color: "text",
    },
    primary: {
      cursor: "pointer",
      color: "background",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      "text-rendering": "optimizeLegibility",
      fontFamily: "body",
      fontWeight: "body",
      a: {
        fontFamily: "Poppins",
        color: "dark",

        fontWeight: 300,
      },
      ".ReactModal__Overlay.ReactModal__Overlay--after-open": {
        zIndex: "9999!important",
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
